<template>
  <v-app>
    <v-container>
      <h1>Contact Us</h1>
      <br>
  
      <p>If you run into trouble, want to ask us a question, or send us feedback, reach out via email at help@baby-go.com and we’ll get back to you within 24 hours.</p>
      <br>

      <p>Or you can reach us on 小红书 @宝贝Go Global.</p>
      <br>

      <p>You can also call us at 1-833-666-5166.</p>
      <br>

      <v-footer padless>
        <v-col class="text-center">
          {{ new Date().getFullYear() }} — <strong>Scope AI Inc.</strong>
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
p {
  color: #adadad;
}
</style>>
